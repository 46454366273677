//v3 funcionan menu, 5 filtros, popup y sitio web

import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import './App.css';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Offcanvas, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet-control-geocoder';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import { Range } from "react-range";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';




// Componente para AutocompleteSelect utilizando react-select
function AutocompleteSelect({ opciones, valor, setValor, placeholder }) {
    const opcionesFormateadas = opciones.map(opcion => ({ label: opcion, value: opcion }));

    const manejarCambio = (seleccion) => {
        setValor(seleccion ? seleccion.value : '');
    };

    return (
        <Select
            options={opcionesFormateadas}
            value={opcionesFormateadas.find(op => op.value === valor)}
            onChange={manejarCambio}
            placeholder={placeholder}
            isClearable
            isSearchable
            styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                control: (provided) => ({
                    ...provided,
                    marginBottom: "7px", // Añade margen inferior al componente
                }),
            }}
        />
    );
}

function App() {
    const [map, setMap] = useState(null);
    const [datos, setDatos] = useState([]);
    const [markers, setMarkers] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [lugarSeleccionado, setLugarSeleccionado] = useState(null);
    const [showOffcanvas, setShowOffcanvas] = useState(false);


    // Estados para los filtros
	const [restaurantes, setRestaurantes] = useState([]); //new
    const [localidades, setLocalidades] = useState([]);
    const [subsectores, setSubsectores] = useState([]);
    const [categoriasPrecio] = useState(['Menos de 10€', '10€ - 20€', '20€ - 30€', 'Más de 30€']);
    const [categoriasPuntuacion] = useState(['Menos de 3 estrellas', '3 - 4 estrellas', 'Más de 4 estrellas']);

    const [filtroRestaurante, setFiltroRestaurante] = useState('');
    const [filtroLocalidad, setFiltroLocalidad] = useState('');
    const [filtroSubsector, setFiltroSubsector] = useState('');
    const [filtroPrecio, setFiltroPrecio] = useState('');
    const [filtroPuntuacion, setFiltroPuntuacion] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShowOffcanvas = () => setShowOffcanvas(true);
    const handleShow = (lugar) => {
        setLugarSeleccionado(lugar);
        setShowModal(true);
    };
    const handleCloseOffcanvas = () => setShowOffcanvas(false);

    const [isMobile, setIsMobile] = useState(false);
    const [showFilters, setShowFilters] = useState(true);

    // imágenes

    // Estado para controlar el Lightbox // línea nueva
    const [isLightboxOpen, setIsLightboxOpen] = useState(false); // línea nueva
    const [lightboxIndex, setLightboxIndex] = useState(0); // línea nueva

    // Lista de imágenes para el Lightbox // línea nueva
    const images = [
        lugarSeleccionado?.['imagen 1'],
        lugarSeleccionado?.['imagen 2'],
    ].filter(Boolean); // línea nueva

    // Función para abrir el Lightbox en la imagen seleccionada // línea nueva
    const handleImageClick = (index) => {
        setLightboxIndex(index);
        setIsLightboxOpen(true);
    }; // línea nueva


    //scroll

        // Función para manejar el evento de desplazamiento (scroll horizontal)
        const handleScroll = (event) => {
            if (event.deltaX < -30 && !showOffcanvas) {
                // Deslizar hacia la izquierda para abrir el panel
                setShowOffcanvas(true);
            } else if (event.deltaX > 30 && showOffcanvas) {
                // Deslizar hacia la derecha para cerrar el panel
                setShowOffcanvas(false);
            }
        };




    // Inicializar el mapa
    useEffect(() => {
        const mapContainer = document.getElementById('map');
        if (mapContainer && mapContainer._leaflet_id) return;

        const apiKey = process.env.REACT_APP_STADIA_API_KEY;
        //console.log('API Key:', apiKey);

        const newMap = L.map(mapContainer, {
            zoomControl: false // Desactiva el control de zoom predeterminado
        }).setView([40.416775, -3.703790], 6);
        L.tileLayer(`https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${apiKey}`, {
            maxZoom: 18,
            attribution: '&copy; Stadia Maps'
        }).addTo(newMap);

            // Añadir los controles de zoom en la parte inferior derecha
    L.control.zoom({
        position: 'bottomright' // Cambia la posición a la esquina inferior derecha
    }).addTo(newMap);
		
		    // Inicializar el proveedor y el control de búsqueda
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
        provider,
        style: 'bar',
        autoClose: true,
        retainZoomLevel: false,
        keepResult: true,
		animateZoom: true,
        showMarker: false, // No mostrar el marcador
        showPopup: false,  // No mostrar el popup con "Mark"
    });

    // Agregar el control de búsqueda al mapa
    newMap.addControl(searchControl);
	
        setMap(newMap);
        setMarkers(L.markerClusterGroup().addTo(newMap));
    }, []);

    

    const iconoPersonalizado = new L.Icon({
        iconUrl: 'https://menuteca.es/archivos/logo3.png',
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
    });

    const [selectedFile, setSelectedFile] = useState('');
    const archivosPredefinidos = [
        { nombre: "Restaurantes BCN", ruta: "/archivos/Restaurantes BCN.xlsx" },
        { nombre: "Restaurantes CAT", ruta: "/archivos/Restaurantes CAT.xlsx" },
        { nombre: "Restaurantes ES vNoviembre", ruta: "/archivos/Restaurantes ES vNoviembre.xlsx" }
      ];

    // Cargar datos desde el archivo Excel
    const cargarDatos = (archivo) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const hoja = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(hoja);
            setDatos(jsonData);

            // Extraer datos únicos
            const restaurantesUnicos = [...new Set(jsonData.map(item => item.nombre))].filter(Boolean);
            const localidadesUnicas = [...new Set(jsonData.map(item => item.localidad))].filter(Boolean);
            const subsectoresUnicos = [...new Set(jsonData.map(item => item.subsector))].filter(Boolean);

            console.log("Restaurantes:", restaurantesUnicos);
            console.log("Localidades:", localidadesUnicas);
            console.log("Subsectores:", subsectoresUnicos);
        };
        reader.readAsArrayBuffer(archivo);
    };

    // Función para cargar datos desde el PHP
    const cargarDatosDesdePHP_old = async () => {
        try {
            const response = await fetch(`https://menuteca.es/backend/consultar.php?token=${apiKey_bbdd}`);
            const text = await response.text();
            console.log("Respuesta cruda del servidor:", text);
            
            const jsonData = JSON.parse(text);
            setDatos(jsonData);
        } catch (error) {
            console.error("Error al cargar datos desde PHP:", error);
        }
    };

    //const apiKey_bbdd = process.env.REACT_APP_BBDD_KEY;
    const apiKey_bbdd='mi_token_super_secreto'


    
    
    // Función para cargar datos desde el PHP
const cargarDatosDesdePHP = async () => {
    try {
        const response = await fetch('https://menuteca.es/backend/consultar.php', {
            //const response = await fetch('/backend/consultar.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Authorization': `Bearer ${apiKey_bbdd}` // Enviar el token en el encabezado
            }
        });
        if (!response.ok) throw new Error('Error al conectar con la base de datos');
        
        const jsonData = await response.json();
        setDatos(jsonData);

        // Extraer datos únicos para los filtros
        const restaurantesUnicos = [...new Set(jsonData.map(item => item.nombre))].filter(Boolean);
        const localidadesUnicas = [...new Set(jsonData.map(item => item.localidad))].filter(Boolean);
        const subsectoresUnicos = [...new Set(jsonData.map(item => item.subsector))].filter(Boolean);

        setRestaurantes(restaurantesUnicos);
        setLocalidades(localidadesUnicas);
        setSubsectores(subsectoresUnicos);

        console.log("Datos cargados desde la base de datos");
    } catch (error) {
        console.error("Error al cargar datos desde PHP:", error);
    }
};



    const handleFileChange = (event) => {
        const archivo = event.target.files[0];
        if (archivo) cargarDatos(archivo);
    };

    const handlePredefinedFileLoad = (ruta) => {
        fetch(ruta)
            .then(response => response.arrayBuffer())
            .then(data => {
                const workbook = XLSX.read(data, { type: 'array' });
                const hoja = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(hoja);
                setDatos(jsonData);
                console.log("Datos cargados desde:", ruta);
            })
            .catch(err => console.error("Error al cargar el archivo:", err));
    };

	const filtrarDatos = (lugar) => {
		console.log("Aplicando filtros...", filtroRestaurante, filtroLocalidad, filtroSubsector, filtroPrecio, filtroPuntuacion);

		const filtrarPorPrecio = (precio) => {
			if (filtroPrecio === 'Menos de 10€') return parseFloat(precio) < 10;
			if (filtroPrecio === '10€ - 20€') return parseFloat(precio) >= 10 && parseFloat(precio) <= 20;
			if (filtroPrecio === '20€ - 30€') return parseFloat(precio) > 20 && parseFloat(precio) <= 30;
			if (filtroPrecio === 'Más de 30€') return parseFloat(precio) > 30;
			return true;
		};

		const filtrarPorPuntuacion = (puntuacion) => {
			if (filtroPuntuacion === 'Menos de 3 estrellas') return parseFloat(puntuacion) < 3;
			if (filtroPuntuacion === '3 - 4 estrellas') return parseFloat(puntuacion) >= 3 && parseFloat(puntuacion) < 4;
			if (filtroPuntuacion === 'Más de 4 estrellas') return parseFloat(puntuacion) >= 4;
			return true;
		};

		return (
			(filtroRestaurante === '' || lugar.nombre?.toLowerCase().includes(filtroRestaurante.toLowerCase())) &&
			(filtroLocalidad === '' || lugar.localidad?.toLowerCase().includes(filtroLocalidad.toLowerCase())) &&
			(filtroSubsector === '' || lugar.subsector?.toLowerCase().includes(filtroSubsector.toLowerCase())) &&
			filtrarPorPrecio(lugar['Precio comida 1']) &&
			filtrarPorPuntuacion(lugar['Puntuación google maps'])
		);
	};


    useEffect(() => {
        if (datos.length > 0) {
            const restaurantesUnicos = [...new Set(datos.map(item => item.nombre))].filter(Boolean);
            const localidadesUnicas = [...new Set(datos.map(item => item.localidad))].filter(Boolean);
            const subsectoresUnicos = [...new Set(datos.map(item => item.subsector))].filter(Boolean);
    
            setRestaurantes(restaurantesUnicos);
            setLocalidades(localidadesUnicas);
            setSubsectores(subsectoresUnicos);
        }
    }, [datos]);
    

    // Inicializar el mapa
    useEffect(() => {
        const mapContainer = document.getElementById('map');
        if (mapContainer && mapContainer._leaflet_id) return;

        const newMap = L.map(mapContainer, {
            zoomControl: false // Desactiva el control de zoom predeterminado
        }).setView([40.416775, -3.703790], 6);
        L.tileLayer(`https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${apiKey}`, {
            maxZoom: 18,
            attribution: '&copy; Stadia Maps'
        }).addTo(newMap);

            // Añadir los controles de zoom en la parte inferior derecha
    L.control.zoom({
        position: 'bottomright' // Cambia la posición a la esquina inferior derecha
    }).addTo(newMap);



    setMap(newMap);
    setMarkers(L.markerClusterGroup().addTo(newMap));
}, []);





    // Mostrar marcadores en el mapa
useEffect(() => {
    if (map && markers) {
        markers.clearLayers();

        // Filtrar los datos antes de añadir los marcadores
        const datosFiltrados = datos.filter(filtrarDatos);

        datosFiltrados.forEach((lugar) => {
            const latitud = parseFloat(lugar.latitud);
            const longitud = parseFloat(lugar.longitud);
            if (!isNaN(latitud) && !isNaN(longitud)) {
                const marker = L.marker([latitud, longitud], { icon: iconoPersonalizado })
                    .on('click', () => handleShow(lugar));
                markers.addLayer(marker);
            }
        });
    }
}, [datos, map, markers, filtroRestaurante, filtroLocalidad, filtroSubsector, filtroPrecio, filtroPuntuacion]);

    // En móvil, el menú estará cerrado por defecto
    useEffect(() => {
        if (isMobile) {
            setShowFilters(false);
        } else {
            setShowFilters(true);
        }
    }, [isMobile]);

    // precios
    const [valores, setValores] = useState([0, 50]); // Rango inicial: 0 a 50

    return (
        <>


        <Button
    variant="primary"
    onClick={handleShowOffcanvas}
    className="floating-button"
>
    <img
        src="/archivos/filtro2.png"
        alt="Más"
        style={{
            width: '20px',
            height: '20px'
            //objectFit: 'contain',
        }}
    />
</Button>

            {/*<Button variant="primary" onClick={handleShowOffcanvas} style={{ margin: '10px' }}>Filtros</Button>
           */} 
                     
                        {/* Cabecera con los logos */} 
                     {/*   <div className="header-container"> */} 
              {/*  <img src={`/archivos/logo1.png`} alt="Logo 1" className="logo" />
                <img src={`/archivos/logo2.png`} alt="Logo 2" className="logo" /> */}
           {/* </div> */} 
{/*
            <Button variant="secondary" onClick={cargarDatosDesdePHP} style={{ marginTop: '10px' }}>
                Cargar datos desde la base de datos
            </Button>
*/} 
        {useEffect(() => {
            cargarDatosDesdePHP();
        }, [])}


            
           <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title style={{ marginBottom: '0' }}>Filtrar por</Offcanvas.Title>
                </Offcanvas.Header>  
                <hr />
                <Offcanvas.Body>
                    {/* Selección de archivo predefinido */}
                    {/*<Form.Select onChange={(e) => handlePredefinedFileLoad(e.target.value)}>
                        <option value="">-- Seleccionar archivo predefinido --</option>
                        {archivosPredefinidos.map((archivo, index) => (
                            <option key={index} value={archivo.ruta}>{archivo.nombre}</option>
                        ))}
                    </Form.Select> */}
                    {/* Opción de cargar archivo personalizado */}
                  {/*  <Form.Control type="file" onChange={handleFileChange} />
                  */}  <AutocompleteSelect opciones={restaurantes} valor={filtroRestaurante} setValor={setFiltroRestaurante} placeholder="Restaurante" style={{ marginBottom: "10px" }}/>
                    <AutocompleteSelect opciones={localidades} valor={filtroLocalidad} setValor={setFiltroLocalidad} placeholder="Localidad" style={{ marginBottom: "10px" }}/>
                    <AutocompleteSelect opciones={subsectores} valor={filtroSubsector} setValor={setFiltroSubsector} placeholder="Tipo de cocina" style={{ marginBottom: "10px" }}/>
                   {/* <AutocompleteSelect opciones={categoriasPrecio} valor={filtroPrecio} setValor={setFiltroPrecio} placeholder="Precio menú" /> */}
                    <AutocompleteSelect opciones={categoriasPuntuacion} valor={filtroPuntuacion} setValor={setFiltroPuntuacion} placeholder="Puntuación Google Maps" style={{ marginBottom: "10px" }}/>
                    
                    <div>

                    <div style={{ marginTop: "20px" }}> {/* Agrega un margen superior */}
    <h5 style={{ marginBottom: "5px" }}>Precio menú</h5> {/* Ajusta el margen inferior */}
    <div style={{ marginBottom: "10px", fontSize: "16px" }}>
        {valores[0]} € - {valores[1]} €
    </div>
</div>

            <Range
                step={1}
                min={0}
                max={50}
                values={valores}
                onChange={(nuevoRango) => setValores(nuevoRango)} // Actualiza los valores dinámicamente
                renderTrack={({ props, children }) => {
                    const { key, ...restProps } = props; // Filtra "key" del objeto "props"
                    const { min, max } = props;

                    const porcentajeMin = ((valores[0] - 0) / (50 - 0)) * 100;
                    const porcentajeMax = ((valores[1] - 0) / (50 - 0)) * 100;
                    console.log(`porcentajeMin: ${porcentajeMin}, porcentajeMax: ${porcentajeMax}`);

                    return (
                        <div
                            {...restProps} // Esparce las demás propiedades sin "key"
                            style={{
                                ...restProps.style,
                                height: "4px",
                                width: "100%",
                                background: `linear-gradient(   
                                    to right,
                                    #ddd 0%,
                                    #ddd ${porcentajeMin}%,
                                    green ${porcentajeMin}%,
                                    green ${porcentajeMax}%,
                                    #ddd ${porcentajeMax}%,
                                    #ddd 100%
                                )`,
                                borderRadius: "1px",
                                position: "relative",
                            }}
                        >
                            {React.Children.map(children, (child, index) =>
                                React.cloneElement(child, { key: index }) // Asigna un "key" único a cada hijo
                            )}
                        </div>
                    );
                }}
                renderThumb={({ props }) => {
                    const { key, ...restProps } = props; // Filtra "key" del objeto "props"
                    return (
                        <div
                            {...restProps} // Esparce las demás propiedades sin "key"
                            style={{
                                ...restProps.style,
                                height: "15px",
                                width: "15px",
                                backgroundColor: "green",
                                borderRadius: "50%",
                            }}
                        />
                    );
                }}
            />
        </div>


                        {/* Nuevos filtros */}
                        <div className="mt-4">
    <h5>Horario</h5>
    <Form.Check 
        type="radio" 
        label="Menú mediodía" 
        name="horario" 
        id="menuMediodia" 
    />
    <Form.Check 
        type="radio" 
        label="Menú noche" 
        name="horario" 
        id="menuNoche" 
    />
    <Form.Check 
        type="radio" 
        label="Menú fin de semana" 
        name="horario" 
        id="menuFinDeSemana" 
    />
    <Form.Check 
        type="radio" 
        label="Menú fin de semana noche" 
        name="horario" 
        id="menuFinDeSemanaNoche" 
    />
</div>
        <div className="mt-4">
            <h5>Variedades</h5>
            <Form.Check type="checkbox" label="Menú vegetariano" />
            <Form.Check type="checkbox" label="Menú sin gluten" />
            <Form.Check type="checkbox" label="Menú infantil" />
            <Form.Check type="checkbox" label="Menú personalizable" />
            <Form.Check type="checkbox" label="Buffet libre" />
            <Form.Check type="checkbox" label="Carta de alérgenos" />
        </div>
        <div className="mt-4">
            <h5>El precio del menú incluye</h5>
            <Form.Check type="checkbox" label="Bebida" />
            <Form.Check type="checkbox" label="Aperitivo" />
            <Form.Check type="checkbox" label="Entrante" />
            <Form.Check type="checkbox" label="Pan" />
            <Form.Check type="checkbox" label="Principal" />
            <Form.Check type="checkbox" label="Segundo" />
            <Form.Check type="checkbox" label="Postre + café/té" />
            <Form.Check type="checkbox" label="Postre" />
            <Form.Check type="checkbox" label="Café/té" />
        </div>
                </Offcanvas.Body>
            </Offcanvas>
            <div id="map" style={{ height: '100vh' }}></div>

            {/* Modal para mostrar la información del punto */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{lugarSeleccionado?.nombre}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Dirección:</strong> {lugarSeleccionado?.DIRECCION || 'No disponible'}</p>
                    {/*<p><strong>CP:</strong> {lugarSeleccionado?.CP || 'No disponible'}</p>*/}
                    <p><strong>Localidad:</strong> {lugarSeleccionado?.localidad || 'No disponible'}</p>
                    <p><strong>Tipo de cocina:</strong> {lugarSeleccionado?.subsector || 'No disponible'}</p>
                    <p><strong>Precio menú: </strong> {lugarSeleccionado?.['Precio comida 1'] || 'No disponible'}€</p>
                    <p><strong>Puntuación Google Maps:</strong> {lugarSeleccionado?.['Puntuación google maps'] || 'No disponible'}</p>
                    {/*<p><strong>Teléfono:</strong> {lugarSeleccionado?.TELEFONO1 || 'No disponible'}</p>
                    */}
                    <p>
                        <strong>Sitio Web: </strong> 
                        {lugarSeleccionado?.SITIO_WEB ? (
                            <a href={lugarSeleccionado.SITIO_WEB} target="_blank" rel="noopener noreferrer">
                                {lugarSeleccionado.SITIO_WEB}
                            </a>
                        ) : 'No disponible'}
                    </p>
                    {lugarSeleccionado?.maps && ( // Solo muestra si lugarSeleccionado.maps tiene un valor
    <p>
        <a href={lugarSeleccionado.maps} target="_blank" rel="noopener noreferrer">
            Google Maps
        </a>
    </p>
)}
                  {/*  <div>
                        {lugarSeleccionado?.['imagen 1'] && (
                            <img src={lugarSeleccionado['imagen 1']} alt="Imagen 1" style={{ width: '100%', marginBottom: '10px' }} />
                        )}
                        {lugarSeleccionado?.['imagen 2'] && (
                            <img src={lugarSeleccionado['imagen 2']} alt="Imagen 2" style={{ width: '100%' }} />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>*/}
                            <div>
                        {/* Al hacer clic en una imagen, abre el Lightbox */}
                        {lugarSeleccionado?.['imagen 1'] && (
                            <img
                                src={lugarSeleccionado['imagen 1']}
                                alt="Imagen 1"
                                style={{ width: '100%', marginBottom: '10px', cursor: 'pointer' }} // línea nueva
                                onClick={() => handleImageClick(0)} // línea nueva
                            />
                        )}
                        Este restaurante renueva su menú regularmente. Descubre un ejemplo reciente:
                        {lugarSeleccionado?.['imagen 2'] && (
                            <img
                                src={lugarSeleccionado['imagen 2']}
                                alt="Imagen 2"
                                style={{ width: '100%', cursor: 'pointer' }} // línea nueva
                                onClick={() => handleImageClick(1)} // línea nueva
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Lightbox para las imágenes */}
            {isLightboxOpen && ( // línea nueva
                <Lightbox
                    mainSrc={images[lightboxIndex]} // línea nueva
                    nextSrc={images[(lightboxIndex + 1) % images.length]} // línea nueva
                    prevSrc={images[(lightboxIndex + images.length - 1) % images.length]} // línea nueva
                    onCloseRequest={() => setIsLightboxOpen(false)} // línea nueva
                    onMovePrevRequest={() =>
                        setLightboxIndex((lightboxIndex + images.length - 1) % images.length) // línea nueva
                    } // línea nueva
                    onMoveNextRequest={() =>
                        setLightboxIndex((lightboxIndex + 1) % images.length) // línea nueva
                    } // línea nueva
                />
            )}
        </>
    );
}

export default App;